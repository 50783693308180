<template>
  <v-container justify-center fluid grid-list-xl>
    <v-layout justify-center wrap>
      <messageBox ref="msgBox" />

      <div style="height: 10px"></div>
      <v-flex md12>
        <v-row>
          <v-col>
            <v-btn color="primary" v-on:click="clearLogs()">{{
              $t("logsmanager-clear")
            }}</v-btn>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <v-autocomplete
              class="minfield"
              outlined
              dense
              :label="$t('logs-mngs-level')"
              ref="severity_id"
              v-model="filters.severityId"
              :items="severities"
              item-text="name"
              item-value="id"
            />
          </v-col>
          <v-col>
            <v-autocomplete
              class="minfield"
              outlined
              dense
              :label="$t('logs-mngs-user')"
              ref="user_id"
              v-model="filters.userId"
              :items="users"
              item-text="name"
              item-value="id"
            />
          </v-col>
          <v-col>
            <v-select
              outlined
              dense
              :label="$t('logs-mngs-period')"
              ref="period"
              v-model="filters.filterByPeriod"
              :items="getPeriodFilters()"
              item-text="name"
              item-value="id"
            >
            </v-select>
          </v-col>
          <v-col>
            <v-dialog
              ref="dialog_start_date"
              v-model="date_start_modal"
              :return-value.sync="filters.filterByCustomDateStart"
              width="290px"
            >
              <template v-slot:activator="{ on }">
                <v-text-field
                  :disabled="filters.filterByPeriod != 9"
                  dense
                  outlined
                  v-model="start_date_formatted"
                  :label="$t('logs-mngs-datestart')"
                  readonly
                  v-on="on"
                ></v-text-field>
              </template>
              <v-date-picker
                @input="
                  $refs.dialog_start_date.save(filters.filterByCustomDateStart)
                "
                v-model="filters.filterByCustomDateStart"
                scrollable
              >
              </v-date-picker>
            </v-dialog>
          </v-col>
          <v-col>
            <v-dialog
              ref="dialog_end_date"
              v-model="date_end_modal"
              :return-value.sync="filters.filterByCustomDateEnd"
              width="290px"
            >
              <template v-slot:activator="{ on }">
                <v-text-field
                  :disabled="filters.filterByPeriod != 9"
                  dense
                  outlined
                  v-model="end_date_formatted"
                  :label="$t('logs-mngs-dateend')"
                  readonly
                  v-on="on"
                ></v-text-field>
              </template>
              <v-date-picker
                @input="
                  $refs.dialog_end_date.save(filters.filterByCustomDateEnd)
                "
                v-model="filters.filterByCustomDateEnd"
                scrollable
              >
              </v-date-picker>
            </v-dialog>
          </v-col>
        </v-row>

        <base-material-card
          color="primary"
          icon="mdi-home"
          inline
          class="px-5 py-3"
        >
          <template v-slot:after-heading>
            <div class="display-1 font-weight-light card-header">
              {{ $t("logsmanager-title") }}
            </div>
          </template>
          <v-divider class="mt-3" />
          <v-progress-circular
            v-if="operationInProgress"
            :indeterminate="true"
            :rotate="0"
            :size="32"
            :width="4"
            color="primary"
          ></v-progress-circular>

          <v-data-table
            v-if="!operationInProgress"
            :items-per-page="perPage"
            :must-sort="true"
            :headers="headers"
            :items="logs"
            :options.sync="options"
            :server-items-length="logsCount"
            :loading="loading"
            :footer-props="dataTableFooterOptions"
          >
            <template v-slot:item.level="{ item }">
              <span v-if="item.level == 0"
                ><v-icon color="orange"
                  >mdi-checkbox-multiple-blank-circle</v-icon
                ></span
              >
              <span v-if="item.level == 1"
                ><v-icon color="green"
                  >mdi-checkbox-multiple-blank-circle</v-icon
                ></span
              >
              <span v-if="item.level == 2"
                ><v-icon color="red"
                  >mdi-checkbox-multiple-blank-circle</v-icon
                ></span
              >
            </template>
            <template v-slot:item.time="{ item }">
              {{ item.time | toLocaleDateTime }}
            </template>
            <template v-slot:item.context="{ item }">
              {{ item.context.toUpperCase() }}
            </template>
          </v-data-table>
        </base-material-card>
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>
import staticData from "../apis/static";
import logsManager from "../apis/logs";
import messageBox from "../components/MessageBox";
import usersManager from "../apis/users";

export default {
  data() {
    return {
      date_start_modal: false,
      date_end_modal: false,

      users: [],
      severities: [
        { name: this.$t("gbl-all-male"), id: null },
        { name: this.$t("logs-level-info"), id: 0 },
        { name: this.$t("logs-level-success"), id: 1 },
        { name: this.$t("logs-level-error"), id: 2 },
      ],

      filters: {
        userId: null,
        severityId: null,
        filterByPeriod: 9,
        filterByCustomDateStart: null,
        filterByCustomDateEnd: null,
      },

      dataTableFooterOptions: staticData.dataTableFooterOptions,
      operationInProgress: false,
      currentPage: 1,
      perPage: 50,
      filter: "",
      headers: [
        {
          value: "level",
          sortable: true,
          text: this.$t("logsmanager-tbl-level"),
        },
        {
          value: "context",
          sortable: true,
          text: this.$t("logsmanager-tbl-context"),
        },
        {
          value: "time",
          sortable: true,
          text: this.$t("logsmanager-tbl-time"),
        },
        { value: "ip", sortable: true, text: this.$t("logsmanager-tbl-ip") },
        {
          value: "user",
          sortable: true,
          text: this.$t("logsmanager-tbl-user"),
        },
        { value: "msg", sortable: true, text: this.$t("logsmanager-tbl-msg") },
      ],
      options: {
        sortBy: ["time"],
        sortDesc: [true],
        itemsPerPage: 50,
        page: 1,
      },
      loading: false,
      logsCount: 0,
      logs: [],
    };
  },

  computed: {
    start_date_formatted: {
      get: function () {
        return this.$options.filters.toLocaleDate(
          this.filters.filterByCustomDateStart
        );
      },
    },

    end_date_formatted: {
      get: function () {
        return this.$options.filters.toLocaleDate(
          this.filters.filterByCustomDateEnd
        );
      },
    },

    siteFilter() {
      return this.filters.siteId;
    },
  },

  watch: {
    options: {
      handler() {
        this.fetchLogs();
      },
    },

    filters: {
      handler() {
        this.fetchLogs();
      },
      deep: true,
    },
  },

  components: {
    messageBox,
  },

  mounted() {
    this.users = [{ id: null, name: this.$t("gbl-all-male") }];
    this.fetchUsers();
  },

  methods: {
    getPeriodFilters: () => {
      return staticData.statisticsPeriods;
    },

    fetchUsers() {
      usersManager.fetchFilterUsers().then((results) => {
        this.users = [
          { id: null, name: this.$t("gbl-all-male") },
          ...results.items,
        ];
      });
    },

    fetchLogs() {
      this.loading = true;
      this.logs = [];
      this.logsCount = 0;
      let ctx = Object.assign({}, this.options);
      ctx.filters = this.filters;
      ctx.sortBy = ctx.sortBy[0];
      ctx.sortDesc = ctx.sortDesc[0];
      ctx.perPage = this.perPage;
      ctx.currentPage = this.currentPage;
      logsManager
        .fetchLogs(ctx)
        .then((result) => {
          this.logsCount = result.totalCount;
          this.logs = result.items;
          this.loading = false;
        })
        .catch((err) => {
          console.log(err);
          this.loading = false;
        });
    },

    clearLogs() {
      this.$refs.msgBox
        .show(this.$t("logs-clear-text"), this.$t("logs-clear-title"), true)
        .then(() => {
          logsManager
            .clearLogs()
            .then(() => {
              this.fetchLogs();
            })
            .catch((err) => {
              console.log(err);
            });
        })
        .catch(() => {});
    },
  },
};
</script>
